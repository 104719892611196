import React from "react"

import invoiceImg from "../../images/solo/invoice.svg"
import invoiceMobileImg from "../../images/solo/invoice-mobile.png"

import ReactGA from "react-ga"
import RightVectorImage from "../../images/new-landing/right-vector.svg"
import { Link } from "gatsby"

export default function Hero({queryParam}) {

  const getStartedNowClick = (navItem) => {
    ReactGA.event({
      category: `Solo Plan LP_Get Started Now`,
      action: `Solo Plan LP_Get Started Now`,
    })
  }

  return (
    <div className="invoice container flex justify-start items-center sm:flex-row flex-col-reverse px-0 sm:px-10">
      <div className="invoice-img">
        <img src={invoiceImg} className="w-full sm:block hidden" />
        <img src={invoiceMobileImg} className="w-full block sm:hidden" />
      </div>
      <div className="content ">
        <div className="header">
          Out with the spreadsheets. <br/>
          In with LUCA Plus
        </div>
        <div className="text-neutral-3 text-16 sm:text-left text-center my-6">
          <span className="sm:block hidden">
            Are you spending endless hours creating and chasing invoices on spreadsheets? <br /><br />
            LUCA Plus eliminates accounting and invoicing headaches with simple tools to help manage invoices and bills for sole traders
            and freelancers. <br /> <br />
            In fact, many customers have experienced saving up to 30 hours a month on back-office work with LUCA Plus.
          </span>
          <span className="block sm:hidden px-8">
            Are you spending endless hours creating and chasing invoices on spreadsheets?
          </span>
        </div>
        <a href={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`}>
          <Link
            onClick={getStartedNowClick}
            className="get-started-btn cursor-pointer focus:outline-none justify-center items-center relative sm:flex hidden uppercase"
            // state={{ isTrial: true, plan: "solo", price: 0 }}
          >
            Get started Now
            <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
          </Link>
        </a>
      </div>
    </div>
  )
}
