import React from "react"

import RightVectorImage from "../../images/new-landing/right-vector.svg"
import triangleImg from "../../images/solo/triangle.svg"
import painterImg from "../../images/solo/painter.svg"
import ReactGA from "react-ga"
import { Link } from "gatsby"

export default function Activity({queryParam}) {

  const startInvoicingNowClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_Start Invoicing Now`,
      action: `Solo Plan LP_Start Invoicing Now`,
    })
  }

  return (
    <div className="activity">
      <div className="container flex sm:flex-row flex-col justify-content-center">
        <div className='left'>
          <div className="header">
            <span className='sm:block hidden'>
              73% of SMEs say that issuing invoices promptly and correctly is a key strategy to combat late payments
            </span>
            <span className='block sm:hidden'>
              73% of SMEs are ready to combat late payments
            </span>
          </div>
          <div className="text-neutral-3 text-16 sm:text-left text-center my-6">
            <span className="sm:block hidden">
              For many businesses, this means recovering from post-pandemic recovery to maintain positive cash flow, requiring twice as much
              effort not only in bookkeeping tasks but also extra vigilance in digital transactions.<br /><br />
              Just like our customer Amy who started her plumbing business, she quickly identified that in order to run a successful business,
              she needed to simply 5 business activities:<br /><br />
              <div className="font-semibold">
                <ul className='list-disc list-inside'>
                  <li>Quickly be able to send invoices after a long days work </li>
                  <li>Remove unnecessary manual accounting processes </li>
                  <li>Automate and centralise her billings </li>
                  <li>Have quick visibility of business cash flow </li>
                  <li>Focus on promoting her business and sourcing new customers</li>
                </ul>
              </div>
              <br />
              That’s where LUCA Plus came in to provide Amy with essential tools to streamline her business accounting operations.
              Amy can now concentrate on scaling her business and boost her business cash flow.
            </span>
            <span className='block sm:hidden px-1'>
              For many businesses, this means recovering from post-pandemic recovery to maintain positive cash flow, requiring twice as
              much effort not only in bookkeeping tasks but also extra vigilance in digital transactions.<br /><br />
              Just like our customer Amy who started her plumbing business, she quickly identified that in order to run a successful business,
              she needed to simplify 5 business activities.<br /><br />
              That's where LUCA Plus came in to help!
            </span>
          </div>
          <a href={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`}>
            <Link
              onClick={startInvoicingNowClick}
              className="start-invoicing-btn bg-mind text-white px-3 py-2 focus:outline-none uppercase flex justify-center items-center cursor-pointer sm:mx-0 mx-auto"
              // state={{ isTrial: true, plan: "solo", price: 0 }}
            >
              Get Started now
              <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
            </Link>
          </a>
        </div>
        <div className='sm:ml-5 right-section'>
          <div className='flex justify-content-center items-center top'>
            <div className='flex relative w-full'>
              <img className='tri-img' src={triangleImg} />
              <div className='label'>
                Amy’s Business Back Office Routine
              </div>
            </div>
            <img src={painterImg} className='sm:block hidden' />
          </div>
          <div className='business-activity-wrapper text-14'>
            <div className='business-list'>
              <span className='number'> 01 </span>
              <span className='text-neutral-3 text'>Amy bills 2-5 jobs a day.</span>
            </div>
            <div className='business-list flex justify-end w-full '>
              <span className='text-neutral-3 mr-3 text'>Amy spends time in the evening and weekends doing invoicing, chasing payments and reconciling bank transactions.</span>
              <span className='number custom-margin'>02</span>

            </div>
            <div className='business-list'>
              <span className='number'> 03 </span>
              <span className='text-neutral-3 text'>Amy starts losing track of what has been paid and what hasn't. She constantly has to reissue invoices.</span>
            </div>
            <div className='business-list flex justify-end w-full '>
              <span className='text-neutral-3 text mr-3'>Amy feels frustrated and starts second guessing her decision to become self-employed. She believes there must be a better way!</span>
              <span className='number custom-margin'>04</span>
            </div>
            <div className='business-list'>
              <span className='number'> 05 </span>
              <span className='text-neutral-3 text'>Amy's bank account goes into overdraft and she has to rely on her credit card and other loans to get through the month. </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
