import React, { useEffect, useState } from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"

import Modali, { useModali } from "../components/modal"
import Top from "../components/solo/top"
import Hero from "../components/solo/hero"
import Persona from "../components/solo/persona"
import Invoice from "../components/solo/invoice"
import Activity from "../components/solo/activity"
import Faq from "../components/solo/faq"
import Start from "../components/solo/start"
import Footer from "../components/pricing/footer"

import "../scss/style.scss"

export default function Partner(props) {
  const [queryParam, setQueryParam] = useState('')
  const [videoModal, toggleVideoModal] = useModali({
    onShow: () => openVideoModal(),
    onHide: () => closeVideoModal(),
  })
  const [howSignupModal, toggleHowSignupModal] = useModali();

  const openVideoModal = () => {
    ReactGA.event({
      category: 'Solo Plan LP_video_open_watch',
      action: `Solo Plan LP_video_open_watch`
    });
  }

  const closeVideoModal = () => {
    ReactGA.event({
      category: 'Solo Plan LP_video_open_close',
      action: `Solo Plan video_open_close`
    });
  }

  useEffect(() => {
    setQueryParam((props.location && props.location.search).replace('?', ''))
  })

  return (
    <div className="h-full solo_landing">
      <SEO title="Your all-in-one tool for better cash flow & secure invoice delivery " einvoice={true} description="Not getting paid faster? LUCA Plus eliminates accounting and invoicing headaches with simple tools for sole traders and freelancers."
      keywords="Accounting software self employed" />
      <Top queryParam={queryParam}/>
      <Hero toggleVideoModal={toggleVideoModal} queryParam={queryParam} toggleHowSignupModal={toggleHowSignupModal}/>
      <Persona />
      <Invoice queryParam={queryParam} />
      <Activity queryParam={queryParam} />
      <Faq />
      <Start queryParam={queryParam} />
      <Footer solo={true} page='Solo Plan LP'/>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/W837a-mj3NI?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
      <Modali.Modal {...howSignupModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/kGajZpdUa6s?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
    </div>
  )
}
