import React from "react"

import ReactGA from "react-ga"

import btnVectorImage from "../../images/solo/btn-vector.svg"
import btnVectorArrowImage from "../../images/luca-pay/btn-vector-arrow.svg"
import { Link } from "gatsby"

export default function Start({queryParam}) {

  const getStartedNowClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_Bottom Banner_Get Started Now`,
      action: `Solo Plan LP_Bottom Banner_Get Started Now`,
    })
  }

  return (
    <section className="start">
      <div className="container  flex  items-center h-full">
        <div className="flex flex-col lg:flex-row justify-between items-center mx-auto w-full sm:pb-0 pt-4">
          <div className="left text-white text-center lg:text-left">
            <h2 className=" d-title mt-0 pt-0">
              Don’t  lose sleep doing  extra paperwork and chasing payments
            </h2>
          </div>
          <div className='sm:pb-0 pb-4 sm:pt-0 pt-10'>
            <a href={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`}>
              <Link
                onClick={getStartedNowClick}
                className="get-started-btn sm:ml-12 cursor-pointer focus:outline-none flex justify-center items-center relative"
              >
                Get Started Now
                <img src={btnVectorImage} className="arrow" alt="right arrow"/>
                <img src={btnVectorArrowImage} className="absolute vector-arrow" alt="right arrow"/>
              </Link>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
