import React, { useEffect, useRef, useState } from "react"

import faqBorderImg from "../../images/new-pricing/faq-bh-border.svg"
import minusImg from "../../images/new-pricing/minus.svg"
import plusImg from "../../images/new-pricing/plus.svg"
import ReactGA from "react-ga"


export default function Demo() {
  const [activeCollapse, setActiveCollapse] = useState(0)

  const toggleCollapse = (collapse) => {
    if (collapse === activeCollapse) {
      setActiveCollapse(0)
    } else {
      setActiveCollapse(collapse)
    }
    ReactGA.event({
      category: `Solo Plan Pricing Page_Frequently Asked Questions`,
      action: `Solo Plan Pricing Page_Frequently Asked Questions`,
    })
  }

  return (
    <div className="faq mx-auto">
      <div className="header relative">
        <div className="flex flex-col">
          Frequently Asked Questions
          <img src={faqBorderImg} className="mx-auto sm:w-auto w-5/6" />
        </div>
      </div>
      <div className='faq-header-wrap'>
      {
        faq.map((data, index) => <div>
          <div className="faq-header flex items-center justify-between cursor-pointer"
               onClick={() => toggleCollapse(index + 1)}>
            {data.title}
            <div>
              {activeCollapse === index + 1 ? <img src={minusImg} />
                : <img src={plusImg} />}
            </div>
          </div>
          {activeCollapse === index + 1 &&
          <div className="content">
            {data.content}
          </div>}
        </div>)
      }
      </div>
    </div>
  )
}

const faq = [
//   {
//   title: "Do both parties need to be LUCA Plus subscribers before they can see the e-invoicing effect?",
//   content: <span>Both parties need to be on the LUCA Plus network or PEPPOL network to deliver e-invoices. <br /><br /> By doing so, we can ensure the other party is legitimate. You are also being protected from potential fraudulent invoices and bills. <br /><br /> It also allows simple and easy automation as both of you are part of the LUCA Plus network.</span>,
// },
  {
    title: "What is LUCA Plus e-invoicing?",
    content: <span>LUCA Plus e-invoicing is a tool you can use to create and send secure digital invoices to your customers or clients.<br /><br />
     No need for any accounting software. Your recipient will receive an invitation to join LUCA Plus via email. Once accepted, you can now seamlessly send and receive invoices.<br /><br />
      Yes, it’s unlimited!
    </span>,
  },
  {
    title: "How do I send and receive invoices?",
    content: <span>Sending and receiving invoices is a lot simpler with the Solo Plan. All you need to do is log in to LUCA Plus,
      choose the receiver, create an invoice and send it away.<br /><br />No need for any other software at your end. LUCA Plus will take care of all the technical details of your user receiving it securely in the correct format for whatever accounting software they choose to use.
    </span>,
  },
]
