import React, { useEffect, useState } from "react"

import rightVectorImg from "../../images/solo/right-vector.svg"
import leftVectorImg from "../../images/solo/left-vector.svg"
import coursolImg1 from "../../images/solo/plumber.png"
import coursolImg2 from "../../images/solo/cleaner.svg"
import coursolImg3 from "../../images/solo/tradie.svg"
import coursolImg4 from "../../images/solo/virtual-assistant.svg"

import ReactGA from "react-ga"


export default function Persona() {

  const [activePersona, setActivePersona] = useState(0)

  useEffect(() => {
    const debounce = setTimeout(() => nextActivePersona(), 3000)
    return () => clearTimeout(debounce)
  })

  const navItemClick = (navItem) => {
    ReactGA.event({
      category: `Solo Plan Pricing Page_Nav Bar_${navItem}`,
      action: `Solo Plan Pricing Page_Nav Bar_${navItem}`,
    })
  }

  const nextActivePersona = () => {
    setActivePersona(activePersona === personaData.length - 1 ? 0 : activePersona + 1)
  }

  const prevActivePersona = () => {
    setActivePersona(activePersona === 0 ? personaData.length - 1 : activePersona - 1)
  }

  return (
    <div className="tools">
      <div className="container flex justify-center">
        <div className="testimonial-section w-full">
          <div className="header">
            Is this dreadful invoicing cycle too close to home for you?
          </div>
          <div className="text-neutral-3 text-16 sm:mt-5 mt-2 mb-2 flex">
            Help is on hand! <span className='sm:block hidden ml-1'>Save hours on admin work using e-invoicing automation.</span>
          </div>

          <div className="flex items-center img-wrap">
            <div onClick={prevActivePersona} className="cursor-pointer left-arrow">
              <img src={leftVectorImg} />
            </div>
            <div className="slider-text-wrap">
              <div className='sm:block flex justify-between'>
                <img src={personaData[activePersona].img} className='character'/>
              </div>
              <div className="sm:text-left text-center sm:justify-start justify-center flex text-neutral-3 content">
                <span className="sm:block hidden">{personaData[activePersona].text}</span>
                <span className="block sm:hidden">{personaData[activePersona].mobileText}</span>
              </div>
            </div>
            <div onClick={nextActivePersona} className="cursor-pointer right-arrow">
              <img src={rightVectorImg} />
            </div>
          </div>
          <div className="flex sm:mt-0 mt-5">
            {personaData.map((data, i) => <div className={`${i === activePersona && "active"} coursol mx-1 cursor-pointer`} onClick={() => setActivePersona(i)} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

const personaData = [
  {
    img: coursolImg1,
    text: "Amy starts losing track of what has been paid and what hasn't. The plumbers she subcontracts have either lost her paper invoice or can't find the emailed invoice so she constantly has to reissue invoices.",
    mobileText: "Amy starts losing track of what has been paid and what hasn't because her subcontractors either lost or could find their invoices.",
  },
  {
    img: coursolImg2,
    text: "Cindy has billed a new client for a deep clean service, two other regulars for post-construction cleaning, and a few more home decluttering jobs. This took her an extra hour a week.",
    mobileText: "Cindy has sent a number of invoices for her cleaning company. This took her an extra hour a week.",
  },
  {
    img: coursolImg3,
    text: "Tom spends his evenings invoicing and chasing customers for his construction projects. It can take 3-5 weeks to receive payment, even for the smallest jobs.",
    mobileText: "Tom spends his evenings invoicing and chasing customers for his construction projects. It can take 3-5 weeks to receive any payment.",
  },
  {
    img: coursolImg4,
    text: "Victor feels overwhelmed and starts to doubt his decision to become self-employed.  At worst, he needs to rely on a credit card to get through the month.",
    mobileText: "Victor feels overwhelmed and starts to doubt his decisions. At worst, he needs to rely on a credit card to get through the month.",
  },
]
