import React from "react"

import Logo from "../../images/logo.svg"
import classNames from "classnames"
import ReactGA from "react-ga"
import { Link } from "gatsby"

export default function Header({ setToggle, toggle, queryParam }) {
  const toggleCls = classNames("navbar-toggler",
    { "collapsed": !toggle },
  )
  const mobileMenuWrapperCls = classNames(
    { "show": toggle },
  )

  const handleToggleMenu = (e) => {
    setToggle(!toggle)
  }

  const navItemClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_Nav Bar_ Contact Us`,
      action: `Solo Plan LP_Nav Bar_ Contact Us`,
    })
  }

  const onLogoClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_logoheader`,
      action: `Solo Plan LP_logoheader`,
    })
  }

  return (
    <div className="top">
      <div className="container">
        <div className="flex items-center justify-between">
          <Link to={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`} onClick={onLogoClick} className="logo flex items-center sm:justify-start justify-content-center">
            <img src={Logo} alt="logo" className="lucaplus-img pr-3 w-1/2" />
            <div className="text-brand whitespace-no-wrap ml-3 text-14">
              Simpler and Faster Business
            </div>
          </Link>
          <div className="flex sm:flex hidden">
            <a
              onClick={navItemClick}
              className="book-demo-btn focus:outline-none flex justify-center items-center mr-5"
              href="/contact"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
