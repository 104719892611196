import React from "react"

import RightVectorImage from "../../images/new-landing/right-vector.svg"
import btnPlayCircleImage from "../../images/solo/btn-play-circle.svg"
import heroRightImg from "../../images/solo/hero-right.png"
import playIcon from "../../images/solo/play_icon.svg"
import heroRightVectorImg from "../../images/solo/hero-right-curve.svg"
import heroLeftVectorImg from "../../images/solo/hero-left-curve.svg"
import ReactGA from "react-ga"
import { Link } from "gatsby"

export default function Hero({ toggleVideoModal, queryParam, toggleHowSignupModal }) {

  const signUpClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_Banner_Sign Up Now`,
      action: `Solo Plan LP_Banner_Sign Up Now`,
    })
  }

  const navItemClick = () => {
    ReactGA.event({
      category: `Solo Plan LP_Nav Bar_ Contact Us`,
      action: `Solo Plan LP_Nav Bar_ Contact Us`,
    })
  }

  return (
    <div className="hero">
      <div className="container custom-container flex justify-between sm:h-full">
        <div className="left">
          <div className="header sm:pr-4">
            <span className='sm:block hidden'>
              Less paperwork. Faster payments. Set up your FREE trial in 2 minutes.
            </span>
            <span className='block sm:hidden'>
              Less paperwork. Faster payments.
            </span>
          </div>
          <div className="text-neutral-3 text-16 my-5 sm:w-3/4 sm:pr-4">
            <span className='sm:block hidden'>
              Manage invoicing & bills in the cloud. LUCA Plus eliminates accounting and invoicing headaches with simple tools for sole traders and freelancers.
            </span>
            <span className='block sm:hidden'>
              Manage invoicing & bills in the cloud.
            </span>
          </div>
          <div className='sm:block flex flex-col-reverse'>
            <div className='flex sm:flex-row flex-col'>
              <Link
                onClick={signUpClick}
                className="signup-btn focus:outline-none uppercase justify-center items-center cursor-pointer hidden sm:flex"
                to={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`}
                state={{ isTrial: true, plan: "solo", price: 0 }}
              >
                Get Started
                <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
              </Link>
              <div
                onClick={toggleHowSignupModal}
                className="signup-btn watch-how-btn focus:outline-none uppercase justify-center items-center cursor-pointer flex sm:ml-3 sm:mt-0 mt-3"
              >
                Watch how to sign up
                <img src={btnPlayCircleImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
              </div>
            </div>
            <div className="flex flex-col sm:hidden items-center justify-content-center">
              <a
                onClick={navItemClick}
                className="book-demo-btn focus:outline-none flex justify-center items-center"
                href="/contact"
              >
                Contact us
              </a>
              <Link
                onClick={signUpClick}
                className="signup-btn focus:outline-none uppercase flex justify-center items-center cursor-pointer"
                to={`/accounting-software-self-employed-soloplan${queryParam && '?' + queryParam}`}
                // state={{ isTrial: true, plan: "solo", price: 0 }}
              >
                Get Started
                <img src={RightVectorImage} className="ml-2 sm:ml-3 arrow" alt="right arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div className="right sm:mt-0 mt-8 flex sm:items-end">
          <img className="hero-left-img" src={heroLeftVectorImg} />
          <div className="hero-right-main-img"><img src={heroRightImg} /></div>
          <div className="play-icon cursor-pointer" onClick={toggleVideoModal}><img src={playIcon} /></div>
          <img src={heroRightVectorImg} className="hero-right-img" />
        </div>
      </div>
    </div>
  )
}
